import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CSVBoxButton } from '@csvbox/react'
import SVGUploadExcel from '../../../../icons/SVG/SVGUploadExcel'
import { getText } from '../../../../lang'
import { notifyError } from '../../../../utils/Notify'
import MatButton from '../../../../components/MatButton'
import './CreateByUpload.scss'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import Div from '../../../../components/Div/Div'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultButton from '../../../../components/MyDefaultButton/MyDefaultButton'
import { getInitialCountry } from '../../../../utils'

const initialStat = {
  fileUploadPage: true,
  hasExcludeCustomers: false,
  updateCustomersData: false,
  days_to_skip: '',
  excludeArchived: false,
  excludeDNC: false,
}

const CreateByUpload = (props) => {
  const [uploadCampaign, setUploadCampaign] = useState(initialStat)
  const navigate = useNavigate()

  const isUsOrg = getInitialCountry(props.organization.twilio_number) === 'us'

  return (
    <MyDefaultPageLayout disableHeader>
      <Div className={'create_by_upload_wrapper'}>
        <span className='create_by_upload_wrapper--title'>
          {getText('TEXT_UPLOAD_WITH_EXCEL_FILE')}
        </span>
        <MyDefaultBlock enableMarginTop fullWidth>
          <CSVBoxButton
            licenseKey={process.env.REACT_APP_CSV_BOX_LICENSE_KEY}
            user={{ user_id: process.env.REACT_APP_CSV_BOX_USER }}
            onImport={(result, data) => {
              if (result) {
                setUploadCampaign({
                  ...uploadCampaign,
                  fileUploadPage: true,
                  import_id: data.import_id,
                  uploadExcel: data.raw_file,
                  uploadFileName: data.original_filename,
                })
              } else {
                notifyError('Fail to upload Data')
              }
            }}
            render={(launch, isLoading) => {
              return (
                <MatButton
                  className='csv-box-button'
                  disabled={isLoading}
                  onClick={launch}
                  buttonText={
                    <>
                      <SVGUploadExcel />
                      {uploadCampaign.uploadFileName ? (
                        <div className='file-name'>
                          {uploadCampaign.uploadFileName}
                        </div>
                      ) : (
                        <div className='no-file'>
                          {getText('TEXT_CLICK_HERE_TO_UPLOAD_YOUR_CSV_EXCEL_FILE')}{' '}
                        </div>
                      )}
                    </>
                  }
                />
              )
            }}
          />
        </MyDefaultBlock>
        <MyDefaultBlock enableMarginTop>
          <MyDefaultSwitch
            title={getText('UPDATE_CUSTOMERS_DATA_BROADCAST_PAGE')}
            value={uploadCampaign.updateCustomersData}
            titleStyle={{ fontSize: 14 }}
            onChangeSwitch={(checked) => {
              setUploadCampaign({
                ...uploadCampaign,
                updateCustomersData: checked,
              })
            }}
            noText
            isFirst
          />
          <MyDefaultSwitch
            title={getText(
              'TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST'
            )}
            value={uploadCampaign.hasExcludeCustomers}
            titleStyle={{ fontSize: 14 }}
            onChangeSwitch={(checked) => {
              setUploadCampaign({
                ...uploadCampaign,
                hasExcludeCustomers: checked,
              })
            }}
            noText
            additionalNumberInput
            additionalNumberInputTitle={getText('WORD_DAY_S')}
            onChangeInput={(e) => {
              if (String(e.target.value)?.length > 3) {
                return false
              } else {
                setUploadCampaign({
                  ...uploadCampaign,
                  days_to_skip: e.target.value,
                })
              }
            }}
            inputValue={uploadCampaign?.days_to_skip || ''}
          />
          <MyDefaultSwitch
            title={getText('TEXT_EXCLUDE_ARCHIVED_CUSTOMERS_FROM_BROADCAST')}
            value={uploadCampaign.excludeArchived}
            titleStyle={{ fontSize: 14 }}
            onChangeSwitch={(checked) => {
              setUploadCampaign({
                ...uploadCampaign,
                excludeArchived: checked,
              })
            }}
            noText
            isLast={!isUsOrg}
          />
          {Boolean(isUsOrg) && (
            <MyDefaultSwitch
              title={getText('TEXT_EXCLUDE_DNC_NUMBERS_FROM_THE_BROADCAST_LIST')}
              titleStyle={{ fontSize: 14 }}
              noText
              isLast
              titleTooltip={getText(
                'TEXT_EXCLUDE_DNC_NUMBERS_FROM_THE_BROADCAST_LIST_TOOLTIP'
              )}
              value={uploadCampaign.excludeDNC}
              onChangeSwitch={(checked) => {
                setUploadCampaign({
                  ...uploadCampaign,
                  excludeDNC: checked,
                })
              }}
            />
          )}
        </MyDefaultBlock>
        <Div className={'create_by_upload_wrapper--footer'}>
          <MyDefaultButton
            buttonText={getText('ACTION_CANCEL')}
            typeButton={'cancel'}
            htmlType={'button'}
            onClick={() => {
              navigate('/broadcasting/create-campaign')
            }}
            minWidth={161}
          />
          <MyDefaultButton
            loading={props.loading}
            buttonText={getText('ACTION_APPLY')}
            minWidth={161}
            onClick={() => {
              if (!uploadCampaign.uploadExcel) {
                notifyError(getText('TEXT_PLESE_UPLOAD_YOUR_CSV_OR_EXCEL_FILE'))
                return
              }
              if (uploadCampaign.hasExcludeCustomers) {
                if (uploadCampaign.days_to_skip) {
                  if (uploadCampaign.days_to_skip < 0) {
                    notifyError('Please write only positive number')
                  } else if (uploadCampaign.days_to_skip > 999) {
                    notifyError('The maximum number can be 999')
                  } else {
                    navigate('/broadcasting/create-campaign/sub-pages', {
                      state: uploadCampaign,
                    })
                  }
                } else {
                  notifyError(getText('TEXT_PLEASE_WRITE_NUMBER_OF_DAYS'))
                }
              } else {
                navigate('/broadcasting/create-campaign/sub-pages', {
                  state: uploadCampaign,
                })
              }
            }}
          />
        </Div>
      </Div>
    </MyDefaultPageLayout>
  )
}

export default CreateByUpload
