import React, { memo } from 'react'
import { Card, Flex } from 'antd'
import Div from '../../../../../components/MatDiv/Div'
import { getText } from '../../../../../lang'
import SVGDashSequenceIcon from '../../../../../icons/SVG/SVGDashSequenceIcon'
import SVGDashSequenceBarIcon from '../../../../../icons/SVG/SVGDashSequenceBarIcon'
import useWindowSize from '../../../../../utils/useWindowSize'
import SVGDashSequenceMobileBarIcon from '../../../../../icons/SVG/SVGDashSequenceMobileBarIcon'
import DashboardInfoTooltip from '../../../DashboardComponents/DashboardInfoTooltip/DashboardInfoTooltip'

const tooltipTitleStyle = { fontWeight: 700, fontSize: 14 }
const tooltipDescStyle = {
  fontWeight: 400,
  fontSize: 12,
  color: 'var(--newLightGrey)',
  margin: '10px 0',
}

const DashboardSequence = ({ dashboardSequenceData }) => {
  const { isTabletPortrait, isMobile } = useWindowSize()

  return (
    <Card className='dashboard_block dashboard_unique_leads'>
      <Div className={`dashboard_block--blurred-part purple`}></Div>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        <SVGDashSequenceIcon />
        <Div className='dashboard_block--header--right'>
          <span>
            {getText('TEXT_DRIP_SEQUENCE_AT_WORK')}
            <DashboardInfoTooltip
              title={
                <Div>
                  <p style={tooltipTitleStyle}>{getText('WORD_LEAD_VOLUME')}</p>
                  <p style={tooltipDescStyle}>
                    {getText('TOOLTIP_SEQUENCE_LEAD_VOLUME')}
                  </p>
                  <p style={tooltipTitleStyle}>{getText('TEXT_MESSAGES_SENT')}</p>
                  <p style={tooltipDescStyle}>
                    {getText('TOOLTIP_SEQUENCE_MESSAGES_SENT')}
                  </p>
                  <p style={tooltipTitleStyle}>
                    {getText('TEXT_AVG_MESSAGE_SENT_PER_USER')}
                  </p>
                  <p style={tooltipDescStyle}>
                    {getText('TOOLTIP_SEQUENCE_AVG_MESSAGES_SENT_PER_USER')}
                  </p>
                  <p style={tooltipTitleStyle}>{getText('WORD_ENGAGED_CLIENTS')}</p>
                  <p style={tooltipDescStyle}>
                    {getText('TOOLTIP_SEQUENCE_ENGAGED_CLIENTS')}
                  </p>
                </Div>
              }
            />
          </span>
        </Div>
      </Flex>
      {isTabletPortrait || isMobile ? (
        <SVGDashSequenceMobileBarIcon
          style={{ margin: '0 auto' }}
          profiles_messaged={dashboardSequenceData?.profiles_messaged || 0}
          avg_per_user_count={dashboardSequenceData?.avg_per_user_count || 0}
          engaged_clients={dashboardSequenceData?.engaged_clients || 0}
          ai_bot_messages={dashboardSequenceData?.ai_bot_messages || 0}
          ai_bot_percentage={dashboardSequenceData?.ai_bot_percentage || 0}
          appointment_conversion_count={
            dashboardSequenceData?.appointment_conversion_count || 0
          }
        />
      ) : (
        <SVGDashSequenceBarIcon
          profiles_messaged={dashboardSequenceData?.profiles_messaged || 0}
          avg_per_user_count={dashboardSequenceData?.avg_per_user_count || 0}
          engaged_clients={dashboardSequenceData?.engaged_clients || 0}
          ai_bot_messages={dashboardSequenceData?.ai_bot_messages || 0}
          ai_bot_percentage={dashboardSequenceData?.ai_bot_percentage || 0}
          appointment_conversion_count={
            dashboardSequenceData?.appointment_conversion_count || 0
          }
        />
      )}
    </Card>
  )
}

export default memo(DashboardSequence)
