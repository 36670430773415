import React, { memo } from 'react'
import { Card, Flex, Typography } from 'antd'
import Div from '../../../../components/MatDiv/Div'
import DashboardInfoTooltip from '../DashboardInfoTooltip/DashboardInfoTooltip'
import utilNumber from '../../../../utils/utilNumber'
import './DashboardBlockPurple.scss'

const DashboardBlockPurple = ({
  count,
  icon,
  title,
  backgroundIcon,
  className,
  tooltipTitle,
}) => {
  return (
    <Card className={`dashboard_block dashboard_block_purple ${className || ''}`}>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        {icon}
        <Div className='dashboard_block--header--right'>
          <span>
            {title}
            {tooltipTitle && (
              <DashboardInfoTooltip title={tooltipTitle} marginLeft={4} />
            )}
          </span>
        </Div>
      </Flex>
      <Typography>{utilNumber.numberFractions(count)}</Typography>
      {backgroundIcon}
    </Card>
  )
}

export default memo(DashboardBlockPurple)
