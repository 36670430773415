import React, { memo, useContext } from 'react'
import dayjs from 'dayjs'
import { DashboardContext } from '../Dashboard'
import MatRangePicker from '../../../components/MatRangePicker/MatRangePicker'
import {
  CUSTOM,
  END_DATE,
  START_DATE,
  USERS_TAB,
  VIDEO_TAB,
} from '../../../devOptions'
import DashboardSelectRange from './DashboardSelectRange'
import DashboardSelectOrgLoc from './DashboardSelectOrgLoc'

const DashboardMainFilters = () => {
  const { selectedRange, setSelectCustomRange, selectedTab } =
    useContext(DashboardContext)

  return (
    <div className='dashboard_page_main_page--header'>
      <div className='dashboard_page_main_page--header--selects'>
        {![USERS_TAB, VIDEO_TAB].includes(selectedTab) && <DashboardSelectOrgLoc />}
        <DashboardSelectRange />
      </div>
      {selectedRange === CUSTOM && (
        <MatRangePicker
          label={''}
          onChange={(date) => {
            let obj = {}
            date &&
              date.map((item, index) => {
                return (obj[index === 0 ? START_DATE : END_DATE] = item.format(
                  index === 0
                    ? 'YYYY-MM-DDT00:00:00.000[Z]'
                    : 'YYYY-MM-DDT23:59:59.999[Z]'
                ))
              })
            if (obj.start_date && obj.end_date) {
              setSelectCustomRange(obj)
            }
          }}
          disabledDate={(current) => {
            return current && current > dayjs().endOf('day')
          }}
        />
      )}
    </div>
  )
}

export default memo(DashboardMainFilters)
